import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, interval, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { CloudBuildService } from '../cloud-build.service';

@Component({
  selector: 'app-build-logs-view',
  templateUrl: './build-logs-view.component.html',
  styleUrls: ['./build-logs-view.component.scss']
})
export class BuildLogsViewComponent implements OnInit, AfterViewChecked, OnDestroy{

  unsubscribe$ = new Subject<void>();
  id: string;
  buildInProgress = false;
  logs: string[] = [];
  private buildStatusSubscription: Subscription;
  currentTableData:any[] = [];
  currentObject:any = null;
  currentObjIndex:number;

  @ViewChild('logContainer') private logContainer!: ElementRef;


  constructor(private route: ActivatedRoute, private cloudBuildService: CloudBuildService) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')!;
    let tableData:any = localStorage.getItem('table-data');
    this.currentTableData = JSON.parse(tableData);
    this.currentObjIndex = this.currentTableData.findIndex((x:any) => x.id === this.id);
    if (this.currentObjIndex > -1) {
      this.currentObject = this.currentTableData[this.currentObjIndex];
    }
    this.pollBuildStatus(this.id);
    
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.logContainer.nativeElement.scrollTop = this.logContainer.nativeElement.scrollHeight;
    } catch(err) { }
  }

  pollBuildStatus(id:any) {
    this.buildStatusSubscription = interval(5000).pipe(
      switchMap(() => this.cloudBuildService.getBuildStatus(id))
    ).subscribe(
      (response) => {
        if (response.status !== 'WORKING' && response.status !== 'QUEUED') {
          this.buildInProgress = false;
          if (this.buildStatusSubscription) {
            this.buildStatusSubscription.unsubscribe();
          }
          this.currentTableData[this.currentObjIndex].status = response.status;
          localStorage.removeItem("table-data");
          localStorage.setItem('table-data', JSON.stringify(this.currentTableData));
        }
        this.fetchLogs(id);
      },
      (error:any) => {
        console.error('Error fetching build status:', error);
      }
    );
  }

  fetchLogs(id:any) {
    this.cloudBuildService.getBuildLogs(id).pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => {
      })
    ).subscribe(
      (response:any) => {
        this.logs = response.split('\n');
      },
      (error:any) => {
        console.error('Error fetching build logs:', error);
      }
    );
  }

  ngOnDestroy() {
    if (this.buildStatusSubscription) {
      this.buildStatusSubscription.unsubscribe();
    }
  }
}
