<div class="login-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Login</mat-card-title>
    </mat-card-header>
    <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput type="text" id="username" [(ngModel)]="username" name="username" required>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" id="password" [(ngModel)]="password" name="password" required>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">Login</button>
    </form>
  </mat-card>
</div>
