import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog'; 

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent {
  constructor(
  public dialogRef: MatDialogRef<ActionDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any){

  }

  closeConfirmAction(user_action?:any){
    this.data['user_action'] = user_action;
    this.dialogRef.close(this.data);
  }
}
