import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AppComponent } from './app.component';
import {  AuthGuard } from './auth.guard';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { BuildLogsViewComponent } from './build-logs-view/build-logs-view.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'home', component: DashboardPageComponent, canActivate: [AuthGuard]  }, // Define home route
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'build-logs/:id', component: BuildLogsViewComponent },
  { path: '**', redirectTo: '/home' } // Add a wildcard route for unmatched paths
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
