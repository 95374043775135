import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginUrl = 'https://cicd-bknd.mymobilex.com/api/login';  // Replace with your actual API URL
  private isLoggedInSubject: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    const token = localStorage.getItem('token');
    this.isLoggedInSubject = new BehaviorSubject<boolean>(!!token);
  }

  login(username: string, password: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', "*");

    return this.http.post<any>(this.loginUrl, { username, password }, {headers:headers}).pipe(
      tap(response => {
        if (response.token) {
          // Optionally store the token or user data
          localStorage.setItem('token', response.token);
          this.isLoggedInSubject.next(true);
        }
      })
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this.isLoggedInSubject.asObservable();
  }

  logout(): void {
    localStorage.removeItem('token');
    this.isLoggedInSubject.next(false);
  }

  
}
