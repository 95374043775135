<div class="page-title">
    <h1 style="color: #0078D4;"><b>Google Cloud Build Console</b></h1>
</div>

<div class="form-container">
    <form [formGroup]="triggerForm">
        <div>
            <div class="row">
                <div class="col">
                    <p class="pt-0 label-name" style="color: #0078D4;">
                        Project Id *
                    </p>
                    <mat-form-field appearance="outline" class="ubskin-select" style="border-color: #0078D4;">
                        <mat-select formControlName="project_id" panelClass="ub-select-bottom" disableOptionCentering="true">
                            <mat-option *ngFor="let projectId of projectIds" [value]="projectId">
                                {{projectId}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="pt-0 label-name" style="color: #0078D4;">
                        Search with Tags
                    </p>
                    <mat-form-field class="ubskin-select" style="border-color: #0078D4;">
                        <mat-label style="color: #004578;">Tags</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Enter Tag">
                            <mat-chip-row *ngFor="let tag of tags"
                                (removed)="remove(tag)">
                                {{tag}}
                                <button matChipRemove [attr.aria-label]="'remove ' + tag">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>

                            <input
                                placeholder="New tag..."
                                [matChipInputFor]="chipGrid"
                                (matChipInputTokenEnd)="add($event)"
                                [formControl]="tagControl"
                            />
                        </mat-chip-grid>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="pt-0 label-name" style="color: #0078D4;">
                        Pipelines *
                    </p>
                    <mat-form-field class="ubskin-select" style="border-color: #0078D4;">
                        <mat-label style="color: #004578;">Trigger Name</mat-label>
                        <input type="text" matInput [formControl]="triggerSearchControl" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="onChangeOfTrigger" (optionSelected)='changeTriggerValue($event.option.value)'>
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option?.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="triggerSearchControl.hasError('required')" style="color: #D83B01;">
                            Trigger Name is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="pt-0 label-name" style="color: #0078D4;">
                        Branch *
                    </p>
                    <mat-form-field appearance="outline" class="ubskin-select" style="border-color: #0078D4;">
                        <input type="text" autocomplete="false" formControlName="branch_name" matInput required placeholder="Branch name">
                        <mat-error *ngIf="triggerForm.get('branch_name')?.touched && triggerForm.get('branch_name')?.hasError('required')" style="color: #D83B01;">
                            Branch Name is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div>
                        <button
                            mat-button
                            class="btn-pc1"
                            [disabled]="triggerForm.invalid || triggerSearchControl.invalid"
                            (click)="onSubmit()"
                            style="background-color: #0078D4; color: white;">
                            Trigger
                        </button>
                        <button
                            mat-button
                            class="btn-pc1"
                            type="reset"
                            (click)="Reset();$event.preventDefault();"
                            style="background-color: #0078D4; color: white;">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="mat-elevation-z8">
    <div class="table-header">
        <mat-form-field>
            <mat-label style="color: #0078D4;">Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. name">
        </mat-form-field>
    </div>
    <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Trigger Column -->
        <ng-container matColumnDef="trigger">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Trigger </th>
            <td mat-cell *matCellDef="let element"> {{element.trigger}} </td>
        </ng-container>

        <!-- Source Column -->
        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
            <td mat-cell *matCellDef="let element"> {{element.source}} </td>
        </ng-container>

        <!-- Branch Column -->
        <ng-container matColumnDef="branch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Branch </th>
            <td mat-cell *matCellDef="let element"> {{element.branch}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Logs </th>
            <td mat-cell *matCellDef="let element">
                <button
                    mat-button
                    (click)="viewLog(element.id)"
                    style="background-color: #0078D4; color: white;"
                    class="btn-pc1">
                    View Logs
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div
        class="mat-table__message"
        *ngIf="dataSource.data.length == 0"
        style="color: #0078D4;">
        No Records Found
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
</div>

