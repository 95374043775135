<mat-card class="build-details-card">
    <mat-card-header>
        <div mat-card-avatar class="status-icon" [ngClass]="{'status-icon-success': currentObject?.status === 'SUCCESS', 'status-icon-working': currentObject?.status === 'WORKING', 'status-icon-queued': currentObject?.status === 'QUEUED'}">
            <mat-icon *ngIf="currentObject?.status && currentObject?.status === 'SUCCESS'">check_circle</mat-icon>
            <mat-icon *ngIf="currentObject?.status && (currentObject?.status === 'WORKING' || currentObject?.status === 'QUEUED')">build</mat-icon>
          </div>
        <mat-card-title>{{currentObject?.status}}: {{id}}</mat-card-title>
        <mat-card-subtitle>Started on {{currentObject?.start_at}}</mat-card-subtitle>
        <button mat-icon-button class="refresh-button">
            <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button class="copy-url-button">
            <mat-icon>link</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
      <div class="build-info">
        <div class="info-row">
          <span class="info-label">Trigger:</span>
          <span class="info-value">{{currentObject.trigger}}</span>
        </div>
        <div class="info-row">
          <span class="info-label">Source:</span>
          <span class="info-value"><mat-icon>code</mat-icon> {{currentObject.source}}</span>
        </div>
        <div class="info-row">
          <span class="info-label">Branch:</span>
          <span class="info-value">{{currentObject.branch}}</span>
        </div>
        <div class="info-row">
          <span class="info-label">Commit:</span>
          <span class="info-value"><a href="#">Test</a></span>
        </div>
      </div>
      <mat-tab-group>
        <mat-tab label="BUILD LOG">
          <div #logContainer class="log-viewer">
            <div *ngFor="let log of logs">
              <pre>{{ log }}</pre>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="EXECUTION DETAILS">
          <!-- Add execution details content here -->
        </mat-tab>
        <mat-tab label="BUILD ARTIFACTS">
          <!-- Add build artifacts content here -->
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
  