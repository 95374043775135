import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  username: string = '';
  password: string = '';

  constructor(private authService: AuthService, private router: Router) {}

  onSubmit() {
    this.authService.login(this.username, this.password).subscribe(response => {
      if (response.token) {
        // Handle successful login, e.g., navigate to a different page
        this.router.navigate(['/home']);
      } else {
        // Handle login failure, e.g., display an error message
      }
    });
  }
}
