<div class="dialog-container">
    <div class="dialog-header">
      <h3>{{ data.title }}</h3>
      <button mat-icon-button mat-dialog-closes [mat-dialog-close]=false>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <mat-dialog-content class="dialog-content">
      <p [innerHtml]="data.content"></p>  
    </mat-dialog-content>
  
    <mat-dialog-actions class="dialog-actions" [ngClass]="data?.alignBtnCenter ? 'center-buttons' : 'left-buttons'">
      <button mat-flat-button class="cancel-button" [mat-dialog-close]=false *ngIf="data?.cancelActionText">{{ data.cancelActionText }}</button>
      <button mat-flat-button class="confirm-button" (click)="closeConfirmAction(data?.confirmActionText)" *ngIf="data?.confirmActionText">{{ data.confirmActionText }}</button>
    </mat-dialog-actions>
  </div>
  