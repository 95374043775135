import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class CloudBuildService {
  private apiUrl = 'https://cicd-bknd.mymobilex.com/api';
  private socket:any;

  headers:any;

  constructor(private http: HttpClient) {
    // this.socket = io('http://localhost:3000');
    const token = localStorage.getItem('token');
     this.headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
   }

  getTriggers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/get-trigger-list`, {headers: this.headers});
  }

  fetchBranchNames(params:any): Observable<any> {
    return this.http.post(`${this.apiUrl}/repo-branch-names`, params , {headers: this.headers});
  }

  runTrigger(params:any): Observable<any> {
    return this.http.post(`${this.apiUrl}/run-trigger`, params , {headers: this.headers});
  }

  getBuildLogs(id:any): Observable<any> {
    return this.http.get(`${this.apiUrl}/get-build-logs/${id}`, { responseType: 'text', headers: this.headers });
  }

  getBuildStatus(id:any): Observable<any> {
    return this.http.get(`${this.apiUrl}/get-build-status/${id}`, {headers: this.headers});
  }
}
