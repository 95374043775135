import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject, finalize, interval, map, startWith, switchMap, takeUntil } from 'rxjs';
import {  MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CloudBuildService } from '../cloud-build.service';
import { ActionDialogComponent } from '../action-dialog/action-dialog.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  providers: [DatePipe]
})
export class DashboardPageComponent {
  unsubscribe$ = new Subject<void>();
  title = 'cloud-ci-cd-client';
  triggerForm: UntypedFormGroup;
  projectIds = ["non-prod-407910"];
  triggerList:any = [];
  cloneTriggerList:any = [];
  branchList = [];
  triggerSearchControl = new FormControl<any>('', [Validators.required]);
  filteredOptions: Observable<any[]>;
  dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
  displayedColumns: string[] = ['id', 'trigger', 'source', 'branch', 'status', 'action'];
  tags:any = [];
  tagControl= new FormControl<any>('', []);
  intervalIds: Map<string, any> = new Map<string, any>();
  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private cloudBuildService: CloudBuildService,
    public dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    const tableData:any = localStorage.getItem('table-data');
    this.dataSource.data = JSON.parse(tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    let filterData = this.dataSource.data.filter((obj:any) => obj.status ==='QUEUED' || obj.status ==='WORKING').map(function(e:any){
      return e.id
    });
    filterData.forEach((id:any) => {
      this.startIntervalRespectiveId(id, 45000);
    });
    this.getTriggerList();
    this.triggerForm = new UntypedFormGroup({
      project_id: new UntypedFormControl('non-prod-407910'),
      branch_name: new UntypedFormControl(null, [Validators.required]),
    });    
    
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.triggerList.filter((option:any) => option.name.toLowerCase().includes(filterValue));
  }

  getTriggerList() {
    this.cloudBuildService.getTriggers()
    .pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => {
      })
    ).subscribe((response:any) => {
      if (response) {
        this.cloneTriggerList = response;
        this.triggerList = response;
        this.triggerSearchControlChange();
      }
    });
  }

  changeTriggerValue(option:any) {
    this.fetchRepoBranchNames(option);
  }

  fetchRepoBranchNames(option?:any) {
    // let triggerName = this.triggerForm.get('trigger_name')?.value || null;
    this.triggerForm.get("branch_name")?.setValue(null);
    let triggerName = option || null;
    let repositoryConfig =  triggerName.repositoryEventConfig;
    let repoPathArray = repositoryConfig.repository.split("/");
    
    let repoName = repoPathArray[repoPathArray.length - 1];
    repoName = repoName.substring(repoName.indexOf('-')+1);

    let branchName = repositoryConfig.push.branch.substring(1, repositoryConfig.push.branch.length-1);
    this.triggerForm.get("branch_name")?.setValue(branchName);
    
    /* let params = {
      repo_name : repoName
    }
    this.cloudBuildService.fetchBranchNames(params)
    .pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => {
      })
    ).subscribe((response:any) => {
      if (response) {
        console.log('branch', response);
        this.branchList = response;
      }
    }); */
  }

  onSubmit() {
    const dialogRef = this.dialog.open(ActionDialogComponent, {
      data: {
        title:  'Run the trigger',
        content: 'Do you want to trigger the <strong>' + this.triggerSearchControl.value?.name  + '</strong> with the <strong>' + this.triggerForm.get('branch_name')?.value + '</strong> branch?',
        cancelActionText: 'No',
        confirmActionText: 'Yes',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.runTrigger();
        }
    });
    
  }

  runTrigger() {
    let triggerObj = this.triggerSearchControl.value || null;
    let branchName = this.triggerForm.get('branch_name')?.value || null;
    let triggerId = triggerObj.id || null;
    let repoPath = triggerObj.resourceName || null;
    let params = {
      trigger_id:triggerId,
      repo_path:repoPath,
      branch_name:branchName
    }
    this.cloudBuildService.runTrigger(params)
    .pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => {
      })
    ).subscribe((response:any) => {
      if (response) {
        let dateTime= this.datePipe.transform(new Date(), 'MMM d, y, h:mm:ss a')!;
        let buildObj = response.metadata?.build;
        this.dataSource.data = this.dataSource.data.concat([{id: buildObj?.id, trigger: triggerObj.name || null, source: buildObj?.source?.gitSource?.url, branch: this.triggerForm.get("branch_name")?.value || null, status:buildObj.status, start_at:dateTime}]);
        localStorage.removeItem("table-data");
        localStorage.setItem('table-data', JSON.stringify(this.dataSource.data));
        this.Reset();
        this.router.navigate(['/build-logs', buildObj?.id]);
      }
    });
  }

  onChangeOfTrigger(option:any): string {
    return option && option.name ? option.name : '';
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  remove(tag:any): void {
    const index = this.tags?.indexOf(tag);
    if (index > -1) {
      this.tags.splice(index, 1);
      this.tags = [...new Set(this.tags)];
      this.updateTriggerList();
    }
  }

  add(event: any): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.tags = [...this.tags, value];
      this.tags = [...new Set(this.tags)];
      this.updateTriggerList();
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  updateTriggerList() {
    let updatedTriggerList:any = [];
    this.tags.forEach((element:any) => {
      this.cloneTriggerList.filter((option:any) => {
        option.tags.filter((tag:any) => {
          if (tag.includes(element)) {
            updatedTriggerList.push(option);
          }
        });
        
      });  
    });
    let uniqList:any = [];
    updatedTriggerList.forEach((x:any) => {
      if(!uniqList.some((y:any) => JSON.stringify(y) === JSON.stringify(x))){
        uniqList.push(x)
      }
    });
    this.triggerList = this.tags.length ? uniqList :this.cloneTriggerList;
    this.triggerSearchControlChange();
  }

  Reset() {
    this.triggerForm.reset();
    this.triggerForm.markAsPristine();
    this.triggerForm.markAsUntouched();
    this.triggerSearchControl.reset();
    this.triggerSearchControl.markAsPristine();
    this.triggerSearchControl.markAsUntouched();
    this.triggerForm.patchValue({
      project_id: "non-prod-407910",
      branch_name: null,
    });
    this.tagControl.reset();
    this.triggerList = this.cloneTriggerList;
    this.triggerSearchControlChange();
    this.tags = [];
  }

  triggerSearchControlChange() {
    this.filteredOptions = this.triggerSearchControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => {
        const name = typeof value === 'string' ? value : value?.name;
        if (!name) {
          this.triggerForm.get("branch_name")?.setValue(null);
        }
        return name ? this._filter(name as string) : this.triggerList.slice();
      }),
    );
  }

  viewLog(id:any){
    this.router.navigate(['/build-logs', id]);
  }

  startIntervalRespectiveId(id: string, intervalDuration: number = 45000) {
    if (!this.intervalIds.has(id)) {
      let intervalId = setInterval(() => {
        this.stopIntervalForRespectiveId(id);
      }, 10000);
      this.intervalIds.set(id, intervalId);
    }
  }

  stopIntervalForRespectiveId(id: string) {
    if (this.intervalIds.has(id)) {
      this.cloudBuildService.getBuildStatus(id)
        .pipe(
          takeUntil(this.unsubscribe$),
          finalize(() => {
          })
        ).subscribe((response:any) => {
          if (response?.status === "SUCCESS") {
            let tableData:any = this.dataSource.data;
            let findIndex = tableData.findIndex((x:any) => x.id === id);
            if (findIndex > -1) {
              tableData[findIndex].status = "SUCCESS";
            }
            this.dataSource.data = tableData;
            localStorage.setItem('table-data', JSON.stringify(this.dataSource.data));
            clearInterval(this.intervalIds.get(id));
            this.intervalIds.delete(id);
          }
      });
    }
      
    if(this.intervalIds.size == 0){
    } 
  }


}
